import { FilterRealEstate } from "./realestate-filters-helper";

// Para venta:
// Dolares

//     Bajo costo: $40,000 a $88,888

//     Moderado: $88,889 a $150,000

//     Moderado-Alto: $150,001 a $250,000

//     Alto: $250,001 a $400,000

//     Premium: $400,001 a $599,999

//     Colección o lujo: $600,000 en adelante

// Pesos

//     Bajo costo (BC): RD$ 2,160,000 a RD$ 4,799,952

//     Moderado (MD): RD$ 4,799,953 a RD$ 8,100,000

//     Moderado-Alto (MA): RD$ 8,100,001 a RD$ 13,500,054

//     Alto (AT): RD$ 13,500,055 a RD$ 21,600,000

//     Premium (PM): RD$ 21,600,001 a RD$ 32,399,946

//     Colección o lujo (CL): RD$ 32,400,000 en adelante

// Estos son los valores que se enviaran dependiendo en que renglón caen

//     Bajo costo: BC

//     Moderado: MD

//     Moderado-Alto: MA

//     Alto: AT

//     Premium: PM

//     Colección o lujo: CL

// Enviar parametro: 

// mm={tipo_perfil} cuando sea asignado el precio minimo

// mma={tipo_perfil} cuando sea asignado el precio maximo

// Alquiler

//     Bajo costo (BC): $250 a $500

//     Moderado (MD): $501 a $1000

//     Moderado-Alto (MA): $1001 a $1500

//     Alto (AT): $1501 a $2000

//     Premium (PM): $2001 a $2500

//     Colección o lujo (CL): $2501 en adelante

//     Bajo costo (BC): RD$ 13,750 a RD$ 27,500

//     Moderado (MD): RD$ 27,501 a RD$ 55,000

//     Moderado-Alto (MA): RD$ 55,001 a RD$ 82,500

//     Alto (AT): RD$ 82,501 a RD$ 110,000

//     Premium (PM): RD$ 110,001 a RD$ 137,500

//     Colección o lujo (CL): RD$ 137,501 en adelante

// Estos son los valores que se enviaran dependiendo en que renglón caen

//     Bajo costo: ABC

//     Moderado: AMD

//     Moderado-Alto: AMA

//     Alto: AAT

//     Premium: APM

//     Colección o lujo: ACL

// Enviar parámetro: 

// mm={tipo_perfil} cuando sea asignado el precio minimo

// mma={tipo_perfil} cuando sea asignado el precio maximo

const priceRanges = {
  sale: {
    us: [
      {
        min: 40_000,
        category: 'bc'
      },
      {
        min: 88_889,
        category: 'md'
      },
      {
        min: 150_001,
        category: 'ma'
      },
      {
        min: 250_001,
        category: 'at'
      },
      {
        min: 400_001,
        category: 'pm'
      },
      {
        min: 600_000,
        category: 'cl'
      }
    ],
    rd: [
      {
        min: 2_160_000,
        category: 'bc'
      },
      {
        min: 4_799_953,
        category: 'md'
      },
      {
        min: 8_100_001,
        category: 'ma'
      },
      {
        min: 13_500_055,
        category: 'at'
      },
      {
        min: 21_600_001,
        category: 'pm'
      },
      {
        min: 32_400_000,
        category: 'cl'
      }
    ],
    default: "sp"
  },
  lease: {
    us: [
      {
        min: 250,
        category: 'abc'
      },
      {
        min: 501,
        category: 'amd'
      },
      {
        min: 1_001,
        category: 'ama'
      },
      {
        min: 1_501,
        category: 'aat'
      },
      {
        min: 2_001,
        category: 'apm'
      },
      {
        min: 2_501,
        category: 'acl'
      }
    ],
    rd: [
      {
        min: 13_750,
        category: 'abc'
      },
      {
        min: 27_501,
        category: 'amd'
      },
      {
        min: 55_001,
        category: 'ama'
      },
      {
        min: 82_501,
        category: 'aat'
      },
      {
        min: 110_001,
        category: 'apm'
      },
      {
        min: 137_501,
        category: 'acl'
      }
    ],
    default: "asp"
  }
}

let lastValidValue = "";

type PriceRangeParams = Pick<FilterRealEstate, "amountMax" | "amountMin" | "businessTypes" | "currencyType">;

export default function getPriceRangeParam({ amountMin, amountMax, currencyType, businessTypes }: PriceRangeParams) {
  let resultMax = null;
  let resultMin = null;
  let resultParams = "";

  if (!amountMax && !amountMin) return lastValidValue;

  if (amountMax && amountMin && +amountMax < +amountMin) return lastValidValue;

  try {
    const prices = priceRanges[businessTypes];
    for (const range of prices[currencyType]) {
      if (amountMin) {
        if (+amountMin >= range.min) {
          resultMin = range.category;
        }
      }

      if (amountMax) {
        if (+amountMax >= range.min) {
          resultMax = range.category;
        }
      }
    }

    resultParams = `${amountMin ? "&mm=" + (resultMin ?? prices.default) : ""}` +
      `${amountMax ? "&mma=" + (resultMax ?? prices.default) : ""}`;

    lastValidValue = resultParams ?? lastValidValue;
    return lastValidValue;

  } catch (error) {
    return lastValidValue;
  }
}
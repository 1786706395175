import { useLocale, useTranslations } from "next-intl";

interface ImprovementKeyValue { value: number; label: string };

let savedLocale: string;
let CImprovements: ImprovementKeyValue[] = [];

export function useIntlImprovements() {
  const translation = useTranslations("formRealestateFilters");
  const locale = useLocale();

  if (savedLocale !== locale) CImprovements = [];

  if (CImprovements.length) return CImprovements;

  CImprovements = [
    {
      value: 15,
      label: translation("balcony"),
    },
    {
      value: 16,
      label: translation("foyer"),
    },
    {
      value: 17,
      label: translation("livingRoom"),
    },
    {
      value: 18,
      label: translation("livingDiningRoom"),
    },
    {
      value: 19,
      label: translation("diningRoom"),
    },
    {
      value: 20,
      label: translation("room"),
    },
    {
      value: 21,
      label: translation("pantry"),
    },
    {
      value: 22,
      label: translation("kitchen"),
    },
    {
      value: 26,
      label: translation("cistern"),
    },
    {
      value: 27,
      label: translation("breakfastRoom"),
    },
    {
      value: 28,
      label: translation("studio"),
    },
    {
      value: 29,
      label: translation("familyRoom"),
    },
    {
      value: 31,
      label: translation("ladder"),
    },
    {
      value: 32,
      label: translation("elevator"),
    },
    {
      value: 33,
      label: translation("coveredTerrace"),
    },
    {
      value: 73,
      label: translation("terrace"),
    },
    {
      value: 74,
      label: translation("gazebo"),
    },
    {
      value: 76,
      label: translation("swimmingPool"),
    },
    {
      value: 80,
      label: translation("courtyard"),
    },
    {
      value: 85,
      label: translation("parking"),
    },
    {
      value: 86,
      label: translation("roofedParking"),
    },
    {
      value: 87,
      label: translation("mezzanine"),
    },
    {
      value: 89,
      label: translation("jacuzzi"),
    },
    {
      value: 90,
      label: translation("services"),
    },
    {
      value: 91,
      label: translation("washing"),
    },
    {
      value: 93,
      label: translation("telecable"),
    },
    {
      value: 94,
      label: translation("cornices"),
    },
    {
      value: 95,
      label: translation("gates"),
    },
    {
      value: 96,
      label: translation("waterTank"),
    },
    {
      value: 98,
      label: translation("well"),
    },
    {
      value: 99,
      label: translation("aC"),
    },
    {
      value: 100,
      label: translation("fans"),
    },
    {
      value: 102,
      label: translation("transferA"),
    },
    {
      value: 103,
      label: translation("inverter"),
    },
    {
      value: 104,
      label: translation("heater"),
    },
    {
      value: 105,
      label: translation("filters"),
    },
    {
      value: 106,
      label: translation("annexes"),
    },
    {
      value: 107,
      label: translation("phone"),
    },
    {
      value: 153,
      label: translation("grids"),
    },
    {
      value: 154,
      label: translation("powerPlant"),
    },
    {
      value: 155,
      label: translation("fullPowerPlant"),
    },
    {
      value: 241,
      label: translation("aCCentral"),
    },
    {
      value: 242,
      label: translation("aCPreinstalled"),
    },
    {
      value: 243,
      label: translation("aCSplit"),
    },
    {
      value: 244,
      label: translation("socialAreas"),
    },
    {
      value: 246,
      label: translation("janitor"),
    },
    {
      value: 247,
      label: translation("electricGate"),
    },
    {
      value: 248,
      label: translation("roofAccess"),
    },
    {
      value: 249,
      label: translation("commonBathroom"),
    },
    {
      value: 250,
      label: translation("garbageDuct"),
    },
    {
      value: 251,
      label: translation("locker"),
    },
    {
      value: 252,
      label: translation("escape"),
    },
    {
      value: 253,
      label: translation("gym"),
    },
    {
      value: 254,
      label: translation("activitiesRoom"),
    },
  ];

  savedLocale = locale;
  return CImprovements;
}

let savedMapLocale: string;
const CImprovementsMap = new Map<number, string>();

export function useIntlImprovementsMap() {
  const vals = useIntlImprovements();
  const locale = useLocale();

  if (savedMapLocale !== locale) CImprovementsMap.clear();

  if (CImprovementsMap.size === 0) vals.forEach(({ value, label }) => CImprovementsMap.set(value, label));

  savedMapLocale = locale;
  return CImprovementsMap;
}

export const staticImprovementMapSpanish = new Map<number, string>([
  [15, "Balcón"],
  [16, "Recibidor"],
  [17, "Sala"],
  [18, "Sala/Comedor"],
  [19, "Comedor"],
  [20, "Estar"],
  [21, "Pantry"],
  [22, "Cocina"],
  [26, "Cisterna"],
  [27, "Desayunador"],
  [28, "Estudio"],
  [29, "Family Room"],
  [31, "Escaleras"],
  [32, "Ascensor"],
  [33, "Terraza techada"],
  [73, "Terraza"],
  [74, "Gazebo"],
  [76, "Piscina"],
  [80, "Patio"],
  [85, "Parqueo"],
  [86, "Parq. Techado"],
  [87, "Mezzanine"],
  [89, "Jacuzzi"],
  [90, "Servicio"],
  [91, "Lavado"],
  [93, "Telecable"],
  [94, "Cornisas"],
  [95, "Verjas"],
  [96, "Tinaco"],
  [98, "Pozo"],
  [99, "A/C"],
  [100, "Abanicos"],
  [102, "Transfer/A"],
  [103, "Inversor"],
  [104, "Calentador"],
  [105, "Filtros"],
  [106, "Anexos"],
  [107, "Teléfono"],
  [153, "Rejas"],
  [154, "Planta Eléctrica (Común)"],
  [155, "Planta Eléctrica Full"],
  [241, "A/C Central"],
  [242, "A/C Preinstalado"],
  [243, "A/C Split"],
  [244, "Areas sociales"],
  [246, "Conserje"],
  [247, "Portón Eléctrico"],
  [248, "Acceso a Techo"],
  [249, "Baño Común"],
  [250, "Ducto Basura"],
  [251, "Locker"],
  [252, "Esc. Emergencia"],
  [253, "Gimnasio"],
  [254, "Salon de Actividades"],
]);
import { useLocale, useTranslations } from "next-intl";

interface PropertyType { id: number; name: string; slug: string };

let savedLocale: string;
let CPropertyTypesArray: PropertyType[] = [];

export function useIntlPropertyTypes() {
  const translation = useTranslations("realestateList.typeFilters");
  const locale = useLocale();

  if (savedLocale !== locale) CPropertyTypesArray = [];

  if (CPropertyTypesArray.length) return CPropertyTypesArray;

  CPropertyTypesArray = [
    {
      id: 1,
      name: translation("apartment"),
      slug: "apartment",
    },
    {
      id: 2,
      name: translation("house"),
      slug: "house",
    },
    {
      id: 3,
      name: translation("commercialPremises"),
      slug: "comercial+site",
    },
    {
      id: 4,
      name: translation("industrialSite"),
      slug: "industrial+site",
    },
    {
      id: 5,
      name: translation("estate"),
      slug: "estate",
    },
    {
      id: 6,
      name: translation("land"),
      slug: "land",
    },
    {
      id: 7,
      name: translation("business"),
      slug: "office",
    },
    {
      id: 8,
      name: translation("building"),
      slug: "building",
    },
    {
      id: 9,
      name: translation("tourist"),
      slug: "turistic",
    },
    {
      id: 10,
      name: translation("hotel"),
      slug: "hotel",
    },
    {
      id: 12,
      name: translation("housePlot"),
      slug: "house+or+land",
    },
    {
      id: 13,
      name: translation("buildingPlot"),
      slug: "building+or+land",
    },
    {
      id: 14,
      name: translation("project"),
      slug: "project",
    },
    {
      id: 15,
      name: translation("penthouse"),
      slug: "penthouse",
    },
    {
      id: 16,
      name: translation("gasStation"),
      slug: "gasstation",
    },
    {
      id: 17,
      name: translation("office"),
      slug: "office+site",
    },
  ];
  return CPropertyTypesArray;
}

let savedMapLocale: string;
const propertyTypesSlugMap = new Map();

export function useIntlPropertyTypesMap() {
  const vals = useIntlPropertyTypes();
  const locale = useLocale();

  if (savedMapLocale !== locale) propertyTypesSlugMap.clear();

  if (propertyTypesSlugMap.size === 0) {
    vals.forEach(el => propertyTypesSlugMap.set(el.slug, el));
  }

  savedMapLocale = locale;
  return propertyTypesSlugMap;
}

export function useIntlPropertyCollectionTypes() {
  const translation = useTranslations("realestateList.typeFilters");
  const CPropertyCollection = [
    {
      id: 1,
      name: translation("apartment"),
      slug: "apartment",
    },
    {
      id: 2,
      name: translation("house"),
      slug: "house",
    },
    {
      id: 9,
      name: translation("tourist"),
      slug: "turistic",
    },
    {
      id: 14,
      name: translation("project"),
      slug: "project",
    },
    {
      id: 15,
      name: translation("penthouse"),
      slug: "penthouse",
    },
  ];
  return CPropertyCollection;
}


export const CPropertyTypes = [
  {
    id: 1,
    name: "Apartamento",
    plural: "Apartamentos",
    slug: "apartment",
  },
  {
    id: 2,
    name: "Casa",
    plural: "Casas",
    slug: "house",
  },
  {
    id: 3,
    name: "Local Comercial",
    plural: "Locales Comerciales",
    slug: "comercial+site",
  },
  {
    id: 4,
    name: "Nave Industrial",
    plural: "Naves Industriales",
    slug: "industrial+site",
  },
  {
    id: 5,
    name: "Finca",
    plural: "Fincas",
    slug: "estate",
  },
  {
    id: 6,
    name: "Terreno",
    plural: "Terrenos",
    slug: "land",
  },
  {
    id: 7,
    name: "Negocio",
    plural: "Negocios",
    slug: "office",
  },
  {
    id: 8,
    name: "Edificio",
    plural: "Edificios",
    slug: "building",
  },
  {
    id: 9,
    name: "Turístico",
    plural: "Turísticos",
    slug: "turistic",
  },
  {
    id: 10,
    name: "Hotel",
    plural: "Hoteles",
    slug: "hotel",
  },
  {
    id: 12,
    name: "Casa o Solar",
    plural: "Casas o Solares",
    slug: "house+or+land",
  },
  {
    id: 13,
    name: "Edificio o Solar",
    plural: "Edificios o Solares",
    slug: "building+or+land",
  },
  {
    id: 14,
    name: "Proyecto",
    plural: "Proyectos",
    slug: "project",
  },
  {
    id: 15,
    name: "Penthouse",
    plural: "Penthouses",
    slug: "penthouse",
  },
  {
    id: 16,
    name: "Estación de Combustible",
    plural: "Estaciones de Combustible",
    slug: "gasstation",
  },
  {
    id: 17,
    name: "Local de Oficina",
    plural: "Locales de Oficina",
    slug: "office+site",
  },
];